// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/form/SubmitButton.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/form/SubmitButton.tsx");
  import.meta.hot.lastModified = "1708984159613.9106";
}
// REMIX HMR END

import React from 'react';
import { useFormContext, useIsSubmitting } from 'remix-validated-form';
import Button from '../common/Button';
export default function SubmitButton(props) {
  _s();
  const {
    children,
    className,
    ...rest
  } = props;
  const isSubmitting = useIsSubmitting();
  const {
    isValid,
    fieldErrors
  } = useFormContext();
  const disabled = props.disabled ? props.disabled : isSubmitting || !isValid;
  console.log(fieldErrors);
  return <Button disabled={disabled} theme={'primary'} className={className} type={'submit'} {...rest}>
      {children}
    </Button>;
}
_s(SubmitButton, "WK9PxqwNW5atI4MPTJM+17xQ/aM=", false, function () {
  return [useIsSubmitting, useFormContext];
});
_c = SubmitButton;
var _c;
$RefreshReg$(_c, "SubmitButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;