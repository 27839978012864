// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/form/InputField.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/form/InputField.tsx");
  import.meta.hot.lastModified = "1708984159613.9106";
}
// REMIX HMR END

import React from 'react';
import classNames from 'classnames';
import { useField } from 'remix-validated-form';
export default function InputField(props) {
  _s();
  const {
    error,
    getInputProps,
    touched
  } = useField(props.name);
  return <div className={classNames('w-full flex flex-col h-auto peer-focus:border peer-focus:border-white rounded-xl', {
    'bg-dark-500': props.color === 'dark',
    'bg-dark-400': props.color === 'light',
    hidden: props.hidden === true
  })}>
      <input placeholder={`Type in a ${props.label}`} {...getInputProps({
      id: props.name,
      ...props
    })} className={classNames('w-full h-full px-4 py-2 peer focus:outline-none focus:ring-0 focus:ring-none rounded-xl order-1 read-only:text-gray-300', {
      'bg-dark-500': props.color === 'dark',
      'bg-dark-400': props.color === 'light'
    })} />
      <div className={classNames('text-xs ml-4 mt-4  order-0 select-none', {
      'text-danger-500 peer-focus:text-danger-500': touched && error,
      'text-dark-50 peer-focus:text-primary-600': !error
    })}>
        {touched && error ? `${props.label}: ${error}` : `${props.label} ${props.required ? '*' : '(optional)'}`}
      </div>

      {props.description && <small className={'text-xs text-dark-200 order-5 px-4 pb-2'}>
          {props.description}
        </small>}
    </div>;
}
_s(InputField, "wFKuC87pXwoak6i7HGPpIPSying=", false, function () {
  return [useField];
});
_c = InputField;
var _c;
$RefreshReg$(_c, "InputField");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;